import { useTranslation } from 'react-i18next'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { Button } from '@politechdev/blocks-design-system'

const ReassignActions = () => {
  const { t } = useTranslation()
  const { setIsReassignmentModalOpen } = usePacketAssignment()

  return (
    <Button.Secondary
      className="table-filters__modal__button"
      onClick={() => setIsReassignmentModalOpen(false)}
    >
      {t('Cancel')}
    </Button.Secondary>
  )
}

export default ReassignActions
