import { useTranslation } from 'react-i18next'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { Button } from '@politechdev/blocks-design-system'

const ReassignmentScheduleButton = () => {
  const { t } = useTranslation()
  const { setIsReassignmentScheduleOpen } = usePacketAssignment()

  return (
    <Button.Secondary onClick={() => setIsReassignmentScheduleOpen(true)}>
      {t(`Manage Schedule`)}
    </Button.Secondary>
  )
}

export default ReassignmentScheduleButton
