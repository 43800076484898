import { useTranslation } from 'react-i18next'
import { FieldBlock, TextField } from '@politechdev/blocks-design-system'
import { List } from 'components'
import PacketListItem from './PacketListItem'
import { getDaysLeft, getPacketLanguage } from '../../../utils'

const PacketList = ({ packets, packetSearch, setPacketSearch }) => {
  const { t } = useTranslation()

  return (
    <>
      <FieldBlock>
        <TextField
          className="table-filters__search margin--bottom"
          id="reprioritization-packet-name-filter"
          label={t('Packet name')}
          value={packetSearch}
          onChange={setPacketSearch}
          icon="Search"
        />
      </FieldBlock>
      <div className="dialog--modal__content__list">
        <List>
          {packets.map(packet => (
            <PacketListItem
              id={`modal-packet-list${packet.id}`}
              key={`modal-packet-list${packet.id}`}
              packetId={packet.id}
              packetName={packet.name}
              daysLeft={getDaysLeft(packet)}
              reprioritization={packet.reprioritization}
              packetLanguage={getPacketLanguage(packet)}
            />
          ))}
        </List>
      </div>
    </>
  )
}
export default PacketList
