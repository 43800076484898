import { Button } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { ButtonBlock } from 'components'
import { usePacketAssignment } from '../../PacketAssignmentContext'

const ScheduleActions = ({
  saveSchedules,
  updatedSchedules,
  newSchedules,
  clearScheduleChanges,
}) => {
  const { t } = useTranslation()

  const hasSomethingToSave = !!newSchedules.length || !!updatedSchedules.length

  const isSaveDisabled =
    !hasSomethingToSave ||
    newSchedules.some(({ minutes, user }) => !user.id || !minutes) ||
    updatedSchedules.some(({ minutes }) => !minutes)

  const { setIsReassignmentScheduleOpen } = usePacketAssignment()

  return (
    <ButtonBlock justify="right">
      <Button.Secondary
        onClick={() => {
          clearScheduleChanges()
          setIsReassignmentScheduleOpen(false)
        }}
      >
        {t('Cancel')}
      </Button.Secondary>
      <Button.Accent
        disabled={isSaveDisabled}
        onClick={() => {
          saveSchedules(false, [...updatedSchedules, ...newSchedules])
          setIsReassignmentScheduleOpen(false)
        }}
      >
        {t('Save Schedule')}
      </Button.Accent>
    </ButtonBlock>
  )
}

export default ScheduleActions
