import { useState } from 'react'
import {
  FieldBlock,
  Button,
  TextField,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import ModalStaffList from 'qualityControl/packetAssignment/staff/ModalStaffList'
import StaffItemAssignButton from 'qualityControl/packetAssignment/staff/StaffItemAssignButton'
import PacketList from './PacketList'
import styles from './ModalReassignment.module.scss'

const ModalReassignment = ({ refreshAssignedPackets }) => {
  const { t } = useTranslation()

  const {
    setIsReassignmentScheduleOpen,
    scheduledForReassignmentByTerm: scheduledUsers,
    getScheduledUsersIsLoading,
  } = usePacketAssignment()

  const [userSearch, setUserSearch] = useState('')

  const filteredUsers = scheduledUsers.filter(user =>
    user.name.toLowerCase().includes(userSearch.toLowerCase())
  )

  return (
    <>
      <PacketList />
      <FieldBlock variant="medium">
        <div className={styles['search-container']}>
          <TextField
            id="reassign-packets-staff-member-search"
            label={t('Staff member')}
            value={userSearch}
            onChange={setUserSearch}
          />
        </div>
        <Button.Secondary onClick={() => setIsReassignmentScheduleOpen(true)}>
          {t('Manage Schedule')}
        </Button.Secondary>
      </FieldBlock>
      <ModalStaffList
        users={filteredUsers}
        isUsersLoading={getScheduledUsersIsLoading}
        AssignmentButton={props => (
          <StaffItemAssignButton
            {...props}
            refreshAssignedPackets={refreshAssignedPackets}
          />
        )}
      />
    </>
  )
}

export default ModalReassignment
